import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AddressLocation from '../../../models/AddressLocation';
import throttle from 'lodash.throttle';
import { Option } from '../../Option';
import LocationIcon from '../icon/LocationIcon';
import { InputSuggestion } from './InputSuggestions';
import * as Nominatim from 'nominatim-browser';
import { useFormAction } from '../../../contexts/FormActionContext';
import { InputStyle } from './Input';

type AddressInputProps = {
  initialValue?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  onAddressChanged: (address: AddressLocation) => void;
  onClear?: () => void;
  autoFocus?: boolean;
  onBlur?: () => void;
  required?: boolean;
  error?: string;
  errorState?: boolean;
  inputStyle?: InputStyle;
};

interface NominatimResponse extends Nominatim.NominatimResponse {
  address: Nominatim.GeocodeAddress & { road: string };
}

const AddressInput: FC<AddressInputProps> = (props) => {
  const { initialValue, placeholder, disabled, label, onAddressChanged, onClear, autoFocus, onBlur, required, error, errorState, inputStyle } = props;
  const { currentAction } = useFormAction();

  const useGoogle = !!import.meta.env.VITE_GMAPS_API_KEY;
  const googleGeocoder = useRef(useGoogle ? new google.maps.Geocoder() : null);
  const googleAutocomplete = useRef(useGoogle ? new google.maps.places.AutocompleteService() : null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState(initialValue || '');
  const [suggestions, setSuggestions] = useState<Option<string, string>[]>([]);
  const [busyGeocoding, setBusyGeocoding] = useState(false);

  useEffect(() => {
    setSearch(initialValue || '');
  }, [initialValue]);

  const googlePredictionsCallback = useCallback(
    (predictions: google.maps.places.QueryAutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus) => {
      if (!predictions || status !== google.maps.places.PlacesServiceStatus.OK) {
        return;
      }

      setSuggestions(
        predictions
          .filter((prediction) => !!prediction.place_id)
          .map((prediction) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let secondaryText = (prediction as any).structured_formatting.secondary_text;
            if (secondaryText) {
              secondaryText = `, ${secondaryText}`;
            }

            return {
              id: prediction.place_id ?? '',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              text: `${(prediction as any).structured_formatting.main_text}${secondaryText ?? ''}`,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              value: (prediction as any).structured_formatting.main_text,
            };
          }),
      );
    },
    [],
  );

  const nominatimPredictionsCallback = useCallback((response: NominatimResponse[]) => {
    if (!response) {
      return;
    }

    setSuggestions(
      response.map((prediction) => ({
        id: `${prediction.lat},${prediction.lon}`,
        text: prediction.display_name,
        value: prediction.address.road,
      })),
    );
  }, []);

  const internalOnClear = !onClear
    ? undefined
    : () => {
        setSearch('');
        setSuggestions([]);
        onClear();
      };

  const throttledSearch = useMemo(
    () =>
      throttle((search) => {
        useGoogle
          ? googleAutocomplete.current?.getQueryPredictions({ input: search }, googlePredictionsCallback)
          : Nominatim.geocode(
              {
                q: search,
                addressdetails: true,
              },
              import.meta.env.VITE_NOMINATIM_URL,
            ).then(nominatimPredictionsCallback);
      }, 500),
    [googlePredictionsCallback, nominatimPredictionsCallback, useGoogle],
  );

  const onChange = useCallback(
    (value: string) => {
      if (!value && onClear) {
        onClear();
      }
      setSearch(value);
      if (value.trim()) {
        throttledSearch(value);
      }
    },
    [onClear, throttledSearch],
  );

  const onOptionPicked = (option: Option<string, string | number>) => {
    if (useGoogle) {
      setBusyGeocoding(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      googleGeocoder.current?.geocode({ placeId: option.id }, (results: any[] | null) => {
        setBusyGeocoding(false);
        const result = results?.[0];
        if (!result) {
          setSearch('');
          return;
        }

        setSearch(result.formatted_address || option.text);
        onAddressChanged({
          address: result.formatted_address || option.text,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        });
        setSuggestions([]);
      });
    } else {
      const values = (option.id as string).split(',');
      setSearch(option.text);
      onAddressChanged({
        address: option.text,
        lat: parseFloat(values[0]),
        lng: parseFloat(values[1]),
      });
      setSuggestions([]);
    }
  };

  const ariaHelpers = useMemo(() => {
    if (!currentAction?.id) return {};

    return {
      'aria-labelledby': `question-title-${currentAction.id}`,
      'aria-describedby': `question-description-${currentAction.id}`,
    };
  }, [currentAction?.id]);

  return (
    <div className="relative">
      <InputSuggestion
        inputConfig={{
          value: search,
          innerRef: inputRef,
          placeholder,
          onClear: internalOnClear,
          label,
          autoFocus,
          onBlur,
          required,
          error,
          errorState,
          style: inputStyle,
          ...ariaHelpers,
        }}
        onChange={(value) => onChange(value)}
        onPick={onOptionPicked}
        disabled={busyGeocoding || disabled}
        customListRenderer={ListRenderer}
        suggestions={suggestions}
        filterItems={false}
        inputStyles="bg-white"
      />
    </div>
  );
};

const ListRenderer: FC<Option<string, string | number>> = (props) => {
  const { text, value } = props;

  const textWithoutMain = text.replace((value as string) + ', ', '');

  return (
    <div>
      <LocationIcon className="mr-2 h-6 w-6" />
      <span className="font-medium">{value}</span>, {textWithoutMain}
    </div>
  );
};

export default AddressInput;
